import axios from 'axios';

export const register = (user) => {
  return axios
    .post(`/user/register`, user)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
    });
};

export const authenticate = (data, next) => {
  if (typeof window !== undefined) {
    if (data) {
      localStorage.setItem('JWT_TOKEN', JSON.stringify(data.jwtToken));
      localStorage.setItem('user', JSON.stringify(data));
      localStorage.setItem('orgId', JSON.stringify(data.orgId));
      axios.defaults.headers.common.Authorization = `Bearer ${data.jwtToken}`;
    } else {
      localStorage.removeItem('JWT_TOKEN');
      localStorage.removeItem('user');
      delete axios.defaults.headers.common.Authorization;
    }

    next();
  }
};

export const logoutUser = () => {
  if (localStorage.getItem('JWT_TOKEN')) localStorage.removeItem('jwt');
  if (localStorage.getItem('user')) localStorage.removeItem('user');
};

export const isAuthenticated = () => {
  return typeof window === undefined
    ? false
    : localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : false;
};

export const getUser = () => {
  return typeof window === undefined
    ? false
    : localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : false;
};
