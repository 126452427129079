// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';

// constant
const icons = {
  IconDashboard,
  IconDeviceAnalytics,
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: 'dashboard',
  title: <FormattedMessage id='dashboard' />,
  type: 'group',
  children: [
    {
      id: 'default',
      title: <FormattedMessage id='default' />,
      type: 'item',
      url: '/dashboard/default',
      icon: icons.IconDashboard,
      breadcrumbs: false,
    },
    // {
    //     id: 'analytics',
    //     title: <FormattedMessage id="analytics" />,
    //     type: 'item',
    //     url: '/dashboard/analytics',
    //     icon: icons.IconDeviceAnalytics,
    //     breadcrumbs: false
    // }
  ],
};

export default dashboard;
