import dashboard from './dashboard';
import application from './application';
import accounting from './accounting';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [
    dashboard,
    // widget,
    application,
    // forms,
    //elements,
    accounting,
    //pages,
    // utilities,
    // support,
    // other,
  ],
};

export default menuItems;
